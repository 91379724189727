import Swiper, { Navigation, Autoplay } from 'swiper';

document.addEventListener('DOMContentLoaded', () => {
  const swiper = new Swiper('.js-top-voice-items', {
    modules: [Navigation, Autoplay],
    spaceBetween: 60,
    loopAdditionalSlides: 1,
    loop: true,
    autoplay: {
      delay: 9000,
    },
    navigation: {
      nextEl: '.js-top-voice-next',
      prevEl: '.js-top-voice-prev',
    },
  });

  const swiper2 = new Swiper('.js-top-magazinelink-items', {
    modules: [Navigation, Autoplay],
    loop: true,
    autoplay: {
      delay: 9000,
    },
    navigation: {
      nextEl: '.js-top-magazine-next',
      prevEl: '.js-top-magazine-prev',
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 60,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30
      },
    }
  });

});
